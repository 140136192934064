import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import * as scss from "./Lab.module.scss"
import { SEO } from 'components/seo/Seo'
import { ISingleLabTemplate } from "data/dataStruct"

const Lab: React.FC<PageProps<ISingleLabTemplate>> = ({ data }) => {
  const { lab } = data
  const componentName = lab.uid
  
  return (
    <div className={scss.root}>
      <SEO title={lab.data.meta_title} description={lab.data.meta_description} />
    </div>
  )
}

export default Lab

// ------------------------------ QUERY
export const query = graphql`
query lab($uid: String!) {
  lab : prismicLabTemplate(uid: {eq: $uid}) {
    ...labTemplateData
  }
}
`